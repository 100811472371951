import { Component, OnInit } from '@angular/core';
import { ToastController,ModalController,ActionSheetController,LoadingController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss'],
})
export class ComponentsComponent implements OnInit {
  loading : HTMLIonLoadingElement;
  
  constructor(
    public toastController: ToastController,
    public modalController: ModalController,
    public actionSheetController: ActionSheetController,
    public alertController: AlertController,
    public loadingCtrl: LoadingController
  ) { }

  ngOnInit() {}

  async presentToast(mensaje,color) {
    const toast = await this.toastController.create({
      message: mensaje,
      duration: 3000,
      color: color,
      position : "top"
    });
    toast.present();
  }

  async presentModal(Page,params) {
    const modal = await this.modalController.create({
      component: Page
    });
    return await modal.present();
  }

  dismissModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async actionSheet(buttonsActions) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Remisiones',
      cssClass: 'my-custom-class',
      buttons: buttonsActions
    });
    await actionSheet.present();
  }
  async actionSheetWithTitle(title, buttonsActions) {
    const actionSheet = await this.actionSheetController.create({
      header: title,
      cssClass: 'my-custom-class',
      buttons: buttonsActions
    });
    await actionSheet.present();
  }
  
  async presentAlertIncidenciaRechazoSKU(header,actions,buttons) {
    const alert = await this.alertController.create({
      header: header,
      inputs: actions,
      buttons: buttons
    });

  await alert.present();
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: "Monitor Logistico",
      message: message,
      buttons: [{
        text: 'Ok',
        role: 'cancel',
        handler: () => {
        }
      }]
    });

  await alert.present();
  }
  
  async presentAlertInputs(header,message,inputs,buttons) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      inputs : inputs,
      buttons: buttons
    });

  await alert.present();
  }

  async presentAlertInputsMaxNumber(cantidadMax,inputID,header,message,inputs,buttons) {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      inputs : inputs,
      buttons: buttons
    });

    await alert.present().then(result => {
      document.getElementById(inputID).setAttribute('max',cantidadMax);
      document.getElementById(inputID).setAttribute('min',"0");
    });
  }

  async presentLoading() {
    this.loading = await  this.loadingCtrl.create({
      spinner: 'circular',
      cssClass: 'my-loading-class',
      duration: 60000,
    });
  
    await this.loading.present();
  }

  async dismissLoading() {
    if(this.loading){
      await this.loading.dismiss();
    }
  }
  
}
