import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'entrega',
    loadChildren: () => import('./entrega/entrega.module').then( m => m.EntregaPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'remisiones-detalle',
    loadChildren: () => import('./remisiones/detalle/detalle.module').then( m => m.DetallePageModule)
  },
  {
    path: 'remisiones-escaner',
    loadChildren: () => import('./remisiones/escaner/escaner.module').then( m => m.EscanerPageModule)
  },
  {
    path: 'embalaje',
    loadChildren: () => import('./embalaje/embalaje.module').then( m => m.EmbalajePageModule)
  },
  {
    path: 'embarque',
    loadChildren: () => import('./embarque/embarque.module').then( m => m.EmbarquePageModule)
  },
  {
    path: 'modal-incidencia',
    loadChildren: () => import('./modal-incidencia/modal-incidencia.module').then( m => m.ModalIncidenciaPageModule)
  },
  {
    path: 'vendedor',
    loadChildren: () => import('./vendedor/vendedor.module').then( m => m.VendedorPageModule)
  },
  {
    path: 'ruteo',
    loadChildren: () => import('./ruteo/ruteo.module').then( m => m.RuteoPageModule)
  },
  {
    path: 'recepcion-tienda',
    loadChildren: () => import('./recepcion/recepcion-tienda.module').then( m => m.RecepcionTiendaPageModule)
  },
  {
    path: 'crossdock',
    loadChildren: () => import('./crossdock/crossdock.module').then( m => m.CrossdockPageModule)
  },
  {
    path: 'acciones-movimientos',
    loadChildren: () => import('./acciones-movimientos/acciones-movimientos.module').then( m => m.AccionesMovimientosPageModule)
  },
  {
    path: 'reportes',
    loadChildren: () => import('./reportes/reportes.module').then( m => m.ReportesPageModule)
  },  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
