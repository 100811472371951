import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigXdService {

  hostname = "";

  urlProd_Typhoon = "monitorlogistico-typhoon-prod.digitalsalesservices.com";
  urlProd_Typhoon_GCP = "typhoon-367516.uc.r.appspot.com";
  urlQA_Typhoon = "monitor-typhoon-qa-dot-digital-322114.uc.r.appspot.com";
  

  environment = "";

  constructor() {
    this.hostname = window.location.hostname;
  }

  getUrlAPI() {
    console.log(this.hostname);
    let urlAPI = '';
    if (this.hostname == this.urlProd_Typhoon_GCP) {
      urlAPI = "https://api-monitorlogistico-typhoon-prod.digitalsalesservices.com/api/";
      this.environment = "";
    }
    else if (this.hostname == this.urlProd_Typhoon) {
      urlAPI = "https://api-monitorlogistico-typhoon-prod.digitalsalesservices.com/api/";
      this.environment = "";
    }
    else {
      urlAPI = "https://typhoon-qa-monitorlogistico-back-3m4jryeozq-uc.a.run.app/api/";
      this.environment = "QA";
    }
    console.log("API", urlAPI);
    return urlAPI;
  }

  getDominioReportes() {
    console.log(this.hostname);
    let urlDominio = '';

    if (this.hostname == this.urlProd_Typhoon_GCP) {
      urlDominio = "https://reportes-monitorlogistico-typhoon-prod.digitalsalesservices.com/";
    }
    else if (this.hostname == this.urlProd_Typhoon) {
      urlDominio = "https://reportes-monitorlogistico-typhoon-prod.digitalsalesservices.com/";
    }
    else if (this.hostname == this.urlQA_Typhoon) {
      urlDominio = "https://reportes-monitorlogistico-typhoon-qa.digitalsalesservices.com/";
    }
    else {
      urlDominio = "https://reportes-monitorlogistico-typhoon-qa.digitalsalesservices.com/";
    }
    return urlDominio;
  }

  getEnvironment() {
    console.log(this.hostname);
    if (
      this.hostname != this.urlProd_Typhoon) {
      this.environment = "QA";
    }
    return this.environment;
  }

}
