export class HomeStatisticsModel {

    constructor(){
        this.Remisiones = new HomeStatisticsItemModel();
        this.Embalaje = new HomeStatisticsItemModel();
        this.Ruteo = new HomeStatisticsItemModel();
        this.Embarque = new HomeStatisticsItemModel();
        this.Incidencias = new HomeStatisticsItemModel();
        this.Rechazos = new HomeStatisticsItemModel();
        this.Solventadas = new HomeStatisticsItemModel();
        this.Rechazadas = new HomeStatisticsItemModel();
        this.Canceladas = new HomeStatisticsItemModel();
        this.Falladas = new HomeStatisticsItemModel();
        this.Entregadas = new HomeStatisticsItemModel();
        this.Reintento = new HomeStatisticsItemModel();
        this.InTransit = new HomeStatisticsItemModel();
    }

    Remisiones: HomeStatisticsItemModel;
    Embalaje: HomeStatisticsItemModel;
    Ruteo: HomeStatisticsItemModel;
    Embarque: HomeStatisticsItemModel;
    Incidencias: HomeStatisticsItemModel;
    Rechazos: HomeStatisticsItemModel;
    Solventadas: HomeStatisticsItemModel;
    Rechazadas: HomeStatisticsItemModel;
    Canceladas: HomeStatisticsItemModel;
    Falladas: HomeStatisticsItemModel;
    Entregadas: HomeStatisticsItemModel;
    Reintento: HomeStatisticsItemModel;
    InTransit: HomeStatisticsItemModel;
}

export class HomeStatisticsItemModel {

    constructor(){
        this.Cantidad = 0;
        this.Promedio = 0;
    }

    Cantidad: number;
    Promedio: number;
}

