import { environment } from 'src/environments/environment';

export class AppConfigModel {

  constructor(data: IAppConfigModel) {
    this.sandbox = data.sandbox;
    this.demo = data.demo;
    this.ahumadaQA = data.ahumadaQA;
    this.ahumadaPROD = data.ahumadaPROD;
    this.typhoonQA = data.typhoonQA;
    this.typhoonPROD = data.typhoonPROD;
    this.benavidesQA = data.benavidesQA;
  }

    get apiUrl(): string {

      let url: string = '';

      switch(environment.type) {
        case 'sandbox':
          url = this.sandbox;
          break;
        case 'benavidesQA':
            url = this.benavidesQA;
            break;
        case 'ahumadaQA':
          url = this.ahumadaQA;
          break;
        case 'ahumadaPROD':
          url = this.ahumadaPROD;
          break;
        case 'typhoonQA':
          url = this.typhoonQA;
          break;
        case 'typhoonPROD':
          url = this.typhoonPROD;
          break;
        case 'demo':
          url = this.demo;
          break;
        default:
          url = this.sandbox;
          break;
      }

      return url;
    }

    sandbox:string;
    demo:string;
    ahumadaPROD:string;
    ahumadaQA:string;
    typhoonPROD:string;
    typhoonQA:string;
    benavidesQA:string;
}

export interface IAppConfigModel {
  sandbox:string;
  demo:string;
  ahumadaPROD:string;
  ahumadaQA:string;
  typhoonPROD:string;
  typhoonQA:string;
  benavidesQA:string;
}
  