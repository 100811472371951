import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ComponentsComponent } from '../../components/components.component'
import { ServicesxdService } from '../../api/servicesxd.service'
import { SecurityService } from '../../security/security.service'

@Component({
  selector: 'app-popover-lps',
  templateUrl: './popover-lps.component.html',
  styleUrls: ['./popover-lps.component.scss'],
})
export class PopoverLpsComponent implements OnInit {
  LPs = [];
  lpPadre = "";

  constructor(
    public navParams:NavParams,
    private serviceXD: ServicesxdService,
    private security : SecurityService,
    public component : ComponentsComponent,
    ) {

   }

  ngOnInit() {
    this.LPs = this.navParams.get('LPs');
    this.lpPadre = this.navParams.get('lpPadre');
    
    console.log(this.LPs);
    console.log(this.lpPadre);
  }

  deleteLPs(lp,indexLPPadre,indexLPHijo){
    this.component.presentAlertInputs(
      "�Desea eliminar este LP?",
      "Al eliminarlo se pasar� a estatus Empacado. (en caso de ser un paquete)",
      [],
      [{
        text: 'No',
        role: 'cancel',
        handler: () => {

        }
      },{
        text: 'Si',
        handler: data => {
          this.apiDeleteLP(lp,indexLPPadre,indexLPHijo);
        }
      }]
    );
  }


  apiDeleteLP(lp,indexLPPadre,indexLPHijo){
    let arrayLPs = [lp.Numero_LP];
    let lpPadreTmp = "";
    if(indexLPHijo!=null){ //Si se va a borrar un item dentro de un contenedor/pallet (Puede ser Contenedor o Paquete) 
      lpPadreTmp = this.LPs[indexLPPadre].Numero_LP;
    }
    else{
      lpPadreTmp = this.lpPadre
    }
    console.log("LPHijo",indexLPHijo);
    console.log("lpPadreTmp",lpPadreTmp);
    console.log("lpPadre",this.lpPadre);

      this.component.presentLoading();
      this.serviceXD.deleteLP(this.security.operador.user_id,lpPadreTmp,arrayLPs).subscribe(response => {
        console.log(response.status);
        let data : any = response.body;
        this.component.dismissLoading();
        
        if(indexLPHijo!=null){
          this.LPs[indexLPPadre].LPsConsolidadas.splice(indexLPHijo,1);
        }
        else{
          this.LPs.splice(indexLPPadre,1);
        }

        this.component.presentToast(data.mensaje,"success");
        
      }, err => {
          console.log(err);
          this.component.dismissLoading();
          this.component.presentAlert(err.error["mensaje"]);
      });
    
  }

}
