import { Injectable } from '@angular/core';
import { Http, Headers, ResponseOptionsArgs } from '@angular/http';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigXdService } from '../config-xd.service';
import { HomeStatisticsItemModel, HomeStatisticsModel } from './homeStatisticsModel';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})

export class ServicesxdService {

  urlService = "";

  headers = new HttpHeaders();
  constructor(
    private http: HttpClient,
    private httpAngular: Http,
    public configXD: ConfigXdService
  ) {
    this.urlService = this.configXD.getUrlAPI();

    this.headers.append("Content-Security-Policy", "default-src 'self' " + this.urlService);
  }


  getHomeStatistics(request): Observable<HttpResponse<HomeStatisticsModel>> {

    let url = this.urlService;

    return this.http.post<HomeStatisticsModel>(url + 'apiXDPRgtStatistics/', request, { headers: this.headers, observe: 'response' });
    //return this.getHomeStatisticsMock();
  }

  private getHomeStatisticsMock(): Observable<HttpResponse<HomeStatisticsModel>> {

    let statistics: HomeStatisticsModel = new HomeStatisticsModel();

    statistics.Canceladas = new HomeStatisticsItemModel();
    statistics.Canceladas.Cantidad = null; //Math.floor(Math.random() * 501);
    statistics.Canceladas.Promedio = null; //Math.floor(Math.random() * 16);

    statistics.Embalaje = new HomeStatisticsItemModel();
    statistics.Embalaje.Cantidad = null;// Math.floor(Math.random() * 501);
    statistics.Embalaje.Promedio = null;//Math.floor(Math.random() * 16);

    statistics.Embarque = new HomeStatisticsItemModel();
    statistics.Embarque.Cantidad = null;//Math.floor(Math.random() * 501);
    statistics.Embarque.Promedio = null;//Math.floor(Math.random() * 16);

    statistics.Incidencias = new HomeStatisticsItemModel();
    statistics.Incidencias.Cantidad = null; //Math.floor(Math.random() * 501)
    statistics.Incidencias.Promedio = Math.floor(Math.random() * 16);

    statistics.Rechazadas = new HomeStatisticsItemModel();
    statistics.Rechazadas.Cantidad = null;//Math.floor(Math.random() * 501);
    statistics.Rechazadas.Promedio = Math.floor(Math.random() * 16);

    statistics.Rechazos = new HomeStatisticsItemModel();
    statistics.Rechazos.Cantidad = null;//Math.floor(Math.random() * 501);
    statistics.Rechazos.Promedio = Math.floor(Math.random() * 16);

    statistics.Remisiones = new HomeStatisticsItemModel();
    statistics.Remisiones.Cantidad = null; //Math.floor(Math.random() * 501);
    statistics.Remisiones.Promedio = Math.floor(Math.random() * 16);

    statistics.Ruteo = new HomeStatisticsItemModel();
    statistics.Ruteo.Cantidad = null; //Math.floor(Math.random() * 501);
    statistics.Ruteo.Promedio = Math.floor(Math.random() * 16);

    statistics.Solventadas = new HomeStatisticsItemModel();
    statistics.Solventadas.Cantidad = null; //Math.floor(Math.random() * 501);
    statistics.Solventadas.Promedio = Math.floor(Math.random() * 16);

    let response = new HttpResponse<HomeStatisticsModel>({ body: statistics });

    return new Observable(obs => obs.next(response));
  }

  searchEmpleado(NumEmpleado, chkPerfil) {
    let body = {
      "UserID": "",
      "NoEmpleado": NumEmpleado,
      "CheckPerfil": chkPerfil

    };
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRgtSearchEmpleado/", body, { headers: this.headers, observe: 'response' })
    return response;
  }


  searchRemision(remision, tienda) {
    let body = {
      "order_number": remision,
      "tienda": tienda

    };
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRgtSearchRemisionV2/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  searchRetrieveRemision(remision, tienda) {
    let body = {
      "order_number": remision,
      "tienda": tienda

    };
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRgtSearcRetrieveRemision/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  updateEstatusSKU(order_number, userID, arraySKUS) {
    let body = {
      "order_number": order_number,
      "user_id": userID,
      "sku_estatus": arraySKUS
    };
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRupEstatusSKURemision/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  updateRemision(remision, userID) {
    let body = {
      "order_number": remision.order_number,
      "user_id": userID,
      "tipo_envio": remision.tipo_envio,
      "tipo_destino": remision.tipo_destino,
      "LPs": remision.LPsFormat
    };
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRupRemision/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  detallePorConsolidar(tienda, userID) {
    let body = { "Tiendas": [tienda], "user_id": userID };
    var url = this.urlService;
    console.log(body);
    var response = this.http.post(url + "apiXDPRgtRemDetConsolidar/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  catalogoMotivosRechazoRem(userID) {
    let body = { "user_id": userID };
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRgtctlMotivosRechazo/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  saveMotivoRechazoSKURemision(orderNumber, codigoSKU, userID, accion, codigo) {
    let body = {
      "order_number": orderNumber,
      "codigoSku": codigoSKU,
      "user_id": userID,
      "accion": accion,
      "descripcion_incidencia": "",
      "codigoRechazo": codigo
    }
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRsvRemCancelacion/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  sessionEmpleado(username, password) {
    let body = {
      "Username": username,
      "Password": password
    };
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRgtSession/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  searchLP(proceso, userID, numeroLP, remSKU, tienda, print, tipoEnvio, crearCasoNoExista, tipoLP) {
    let body = {
      "stepProceso": proceso,
      "user_id": userID,
      "tipo_LP": tipoLP,
      "crearCasoNoExista": crearCasoNoExista,
      "tienda": tienda,
      "historico": false,
      "print": print,
      "tipoEnvio": tipoEnvio,
      "showArraySKUs": true
    }
    if (numeroLP) {
      body["Numero_LP"] = numeroLP;
    }
    else if (remSKU) {
      body["Remision-sku"] = remSKU;
    }
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRgtSearchLPsV2/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  searchLPsInRemisionSKU(proceso, userID, numeroLP, remSKU, tienda, print, tipoEnvio) {
    let body = {
      "stepProceso": proceso,
      "user_id": userID,
      "crearCasoNoExista": false,
      "tienda": tienda,
      "historico": false,
      "print": print,
      "tipoEnvio": tipoEnvio,
      "showArraySKUs": true
    }
    if (numeroLP) {
      body["Numero_LP"] = numeroLP;
    }
    else if (remSKU) {
      body["Remision-sku"] = remSKU;
    }
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRgtSearchLPsByRemision/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  searchRecibo(userID, idEmbarque, numeroLP, tienda) {
    let body =
    {
      "user_id": userID,
      "tienda": tienda
    }

    if (idEmbarque) {
      body["IdEmbarque"] = idEmbarque;
    }
    else if (numeroLP) {
      body["numeroLP"] = numeroLP;
    }

    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRgtRecibo/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  crearLP(tipo, userID, tienda, LPs) {
    let body = {
      "tipo_LP": tipo,
      "user_id": userID,
      "tienda": tienda,
      "numeroRack": 0,
      "nivelRack": 0,
      "LPs": LPs
    }
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRsvCreaLP/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  updateLP(proceso, LPpadre, userID, tienda, LPs, datosConductor, datosEmpleado) {
    if (!datosConductor) { //Si la llamada no viene de Embarque o entrega Xdoc
      datosConductor = { name: '', ecoUnidad: '', empresa: '', signatureB64: '' }
    }

    if (!datosEmpleado) {
      datosEmpleado = { nombre: "", UserID: "", numeroEmpleado: "", tiendas: "" };
    }


    let body =
    {
      "stepProceso": proceso,
      "user_id": userID,
      "Numero_LP_Hijo": LPs,
      "tienda": tienda,
      "datosEmbarque": {
        "nombreChofer": datosConductor.name,
        "unidadTransporte": datosConductor.ecoUnidad,
        "empresa": datosConductor.empresa,
        "firmaEletronica": ''
        // "firmaEletronica": datosConductor.signatureB64
      },
      "idEmpleadoEntregado": datosEmpleado.UserID,
      "comentarios": ""
    }

    if (LPpadre) {
      body["Numero_LP_Padre"] = LPpadre;
    }

    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRupLPs/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  deleteLP(userID, numeroLPpadre, lpsHijos) {
    let body =
    {
      "user_id": userID,
      "Numero_LP_Hijo": lpsHijos,
      "empty": false
    }

    if (numeroLPpadre) {
      body["Numero_LP_Padre"] = numeroLPpadre;
    }
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRdlLPs/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  saveSolveIncidencia(orderNumber, codigoSKU, userID) {
    let body = {
      "order_number": orderNumber,
      "codigoSku": codigoSKU,
      "user_id": userID
    }
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRupSolveIncidenciaSKU/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  entregaEtiquetaSurtido(remisiones, userID, tienda) {
    let body = {
      "order_line": remisiones,
      "numeroVendedor": userID,
      "seccion": "",
      "tienda_surtido": tienda
    }
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRsvSKUEntregado/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  updateTrazoLogistico(numeroLP, tipoEnvio, tipoDestino, userID, tienda) {
    let body = {
      "user_id": userID,
      "Numero_LP": numeroLP,
      "tienda": tienda,
      "tipo_envio": tipoEnvio,
      "tipo_destino": tipoDestino
    }
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRupTrazoLogistico/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  saveEmbarqueFormData(proceso, LPpadre, userID, tienda, LPs, datosConductor, datosEmpleado) {

    let headers = new Headers();
    this.headers.append("Accept", 'application/json');
    this.headers.append('Content-Type', 'multipart/form-data');

    if (!datosConductor) { //Si la llamada no viene de Embarque o entrega Xdoc
      datosConductor = { name: '', ecoUnidad: '', empresa: '', signatureB64: '' }
    }

    if (!datosEmpleado) {
      datosEmpleado = { nombre: "", UserID: "", numeroEmpleado: "", tiendas: "" };
    }

    var blob = this.dataURItoBlob(datosConductor.signatureB64);
    // var fd = new FormData(document.forms[0]);

    var file = new File([blob], "fileName.jpeg", {
      type: "'image/jpeg'"
    });

    let body = new FormData();
    body.append('stepProceso', proceso);
    body.append('user_id', userID);
    body.append('tienda', tienda);
    body.append('Numero_LP_Hijo', LPs);
    body.append('nombreChofer', datosConductor.name);
    body.append('unidadTransporte', datosConductor.ecoUnidad);
    body.append('empresa', datosConductor.empresa);
    body.append('img_firma', file);
    body.append('idEmpleadoEntregado', datosEmpleado.UserID);

    body.forEach((value, key) => {
      console.log(key, value);
    });

    var url = this.urlService;
    // console.log(body);
    var response = this.httpAngular.post(url + "apiXDPRsvEmbarqueLP/", body, { headers: headers }).map(res => res.json());
    return response;
  }

  saveEmbarque(proceso, LPpadre, userID, tienda, LPs, datosConductor, datosEmpleado) {
    if (!datosConductor) { //Si la llamada no viene de Embarque o entrega Xdoc
      datosConductor = { name: '', ecoUnidad: '', empresa: '', signatureB64: '' }
    }

    if (!datosEmpleado) {
      datosEmpleado = { nombre: "", UserID: "", numeroEmpleado: "", tiendas: "" };
    }


    let body =
    {
      "stepProceso": proceso,
      "user_id": userID,
      "Numero_LP_Hijo": LPs,
      "tienda": tienda,
      "datosEmbarque": {
        "nombreChofer": datosConductor.name,
        "unidadTransporte": datosConductor.ecoUnidad,
        "empresa": datosConductor.empresa,
        "firmaEletronica": ''
        // "firmaEletronica": datosConductor.signatureB64
      },
      "idEmpleadoEntregado": datosEmpleado.UserID,
      "comentarios": ""
    }

    if (LPpadre) {
      body["Numero_LP_Padre"] = LPpadre;
    }

    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRupLPs/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  uploadImageEmbarque(idEmbarque, datosConductor) {

    let headers = new Headers();
    this.headers.append("Accept", 'application/json');
    this.headers.append('Content-Type', 'multipart/form-data');

    if (!datosConductor) { //Si la llamada no viene de Embarque o entrega Xdoc
      datosConductor = { name: '', ecoUnidad: '', empresa: '', signatureB64: '' }
    }

    var blob = this.dataURItoBlob(datosConductor.signatureB64);
    // var fd = new FormData(document.forms[0]);

    var file = new File([blob], idEmbarque + ".png", {
      type: "'image/png'"
    });

    let body = new FormData();
    body.append('descripcion', idEmbarque + ".png");
    body.append('comentarios', idEmbarque + ".png");
    body.append('img_firma', file);

    body.forEach((value, key) => {
      console.log(key, value);
    });

    var url = this.urlService;
    // console.log(body);
    var response = this.httpAngular.post(url + "apiXDsvFileUpload/", body, { headers: headers }).map(res => res.json());
    return response;
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  // getTokenEmbededBI(tipo) {
  //   let endpoint = "";
  //   if(tipo=="dashboard"){
  //     endpoint = "getEmbedTokenDashboard";
  //   }
  //   else if(tipo=="reporte"){
  //     endpoint = "getEmbedTokenReporte";
  //   }
  //   var response = this.http.get("https://dashboard-xd.azurewebsites.net/"+endpoint)
  //   return response;
  // }

  getTokenEmbededBI(tipo) {

    let endpoint = "";

    if (tipo == "dashboard-Ahumada") {

      endpoint = "getEmbedTokenDashboard";

    } else if (tipo == "reporte-Ahumada") {

      endpoint = "getEmbedTokenReporte";
    }

    else if (tipo == "dashboard-Typhoon") {

      endpoint = "getEmbedTokenDashboardSportico";
    }

    else if (tipo == "reporte-Typhoon") {

      endpoint = "getEmbedTokenReporteSportico";
    }

    var response = this.http.get("https://tokendashboardmonitorlogistico.azurewebsites.net/" + endpoint)

    return response;
  }

  getRemisionesPiendentes(request: any): Observable<HttpResponse<any>> {

    return this.http.post(this.urlService + 'apiXDPRgtRemPendientes/', request, { headers: this.headers, observe: 'response' });
    //return this.getRemisionesPiendentesMock();
  }

  getRemisionesPiendentesMock(): Observable<HttpResponse<any>> {
    let data = [
      {
        Tienda: "17",
        Remision: "000000001",
        Cliente: "GustavoQF Almeida",
        Sku: "78753",
        FechaAsignacion: "2020-10-28T08:00:00"
      },
      {
        Tienda: "17",
        Remision: "000000001",
        Cliente: "GustavoQF Almeida",
        Sku: "78754",
        FechaAsignacion: "2020-10-28T08:00:00"
      },
      {
        Tienda: "0017",
        Remision: "000000141",
        Cliente: "Juan Casas",
        Sku: "62367",
        FechaAsignacion: "2020-10-21T19:18:27"
      },
      {
        Tienda: "0017",
        Remision: "000000141",
        Cliente: "Juan Casas",
        Sku: "78377",
        FechaAsignacion: "2020-10-21T19:18:27"
      },
      {
        Tienda: "0017",
        Remision: "000000141",
        Cliente: "Juan Casas",
        Sku: "9028",
        FechaAsignacion: "2020-10-21T19:18:27"
      },
      {
        Tienda: "0017",
        Remision: "000000158",
        Cliente: "Juan Casas",
        Sku: "78377",
        FechaAsignacion: "2020-10-22T16:17:38"
      },
      {
        Tienda: "0017",
        Remision: "000000158",
        Cliente: "Juan Casas",
        Sku: "9028",
        FechaAsignacion: "2020-10-22T16:17:38"
      },
      {
        Tienda: "0017",
        Remision: "000000215",
        Cliente: "MAnuel Casas",
        Sku: "9028",
        FechaAsignacion: "2020-10-24T19:27:17"
      },
      {
        Tienda: "0017",
        Remision: "000000356",
        Cliente: "Manuel Casas",
        Sku: "74832",
        FechaAsignacion: "2020-10-24T05:27:20"
      },
      {
        Tienda: "0017",
        Remision: "000000356",
        Cliente: "Manuel Casas",
        Sku: "82606",
        FechaAsignacion: "2020-10-24T05:27:20"
      },
      {
        Tienda: "0017",
        Remision: "000000356",
        Cliente: "Manuel Casas",
        Sku: "83152",
        FechaAsignacion: "2020-10-24T05:27:20"
      }
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000358",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "17761",
      //   "FechaAsignacion": "2020-10-24T05:45:02"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000358",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "74832",
      //   "FechaAsignacion": "2020-10-24T05:45:02"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000358",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "82606",
      //   "FechaAsignacion": "2020-10-24T05:45:02"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000358",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "83152",
      //   "FechaAsignacion": "2020-10-24T05:45:02"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000362",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "74832",
      //   "FechaAsignacion": "2020-10-24T06:17:58"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000362",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "82606",
      //   "FechaAsignacion": "2020-10-24T06:17:58"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000362",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "83152",
      //   "FechaAsignacion": "2020-10-24T06:17:58"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000364",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "17761",
      //   "FechaAsignacion": "2020-10-24T06:38:40"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000364",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "74832",
      //   "FechaAsignacion": "2020-10-24T06:38:40"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000364",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "82606",
      //   "FechaAsignacion": "2020-10-24T06:38:40"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000364",
      //   "Cliente": "Manuel Casas",
      //   "Sku": "83152",
      //   "FechaAsignacion": "2020-10-24T06:38:40"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000380",
      //   "Cliente": "Juan Casas",
      //   "Sku": "44750",
      //   "FechaAsignacion": "2020-10-24T15:30:06"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000380",
      //   "Cliente": "Juan Casas",
      //   "Sku": "46683",
      //   "FechaAsignacion": "2020-10-24T15:30:06"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000380",
      //   "Cliente": "Juan Casas",
      //   "Sku": "86252001",
      //   "FechaAsignacion": "2020-10-24T15:30:06"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000693",
      //   "Cliente": "Juan Casas",
      //   "Sku": "9028",
      //   "FechaAsignacion": "2020-11-05T02:35:36"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000740",
      //   "Cliente": "Ana Chessani",
      //   "Sku": "87800",
      //   "FechaAsignacion": "2020-11-05T23:11:44"
      // },
      // {
      //   "Tienda": "0017",
      //   "Remision": "000000761",
      //   "Cliente": "Ana Chessani",
      //   "Sku": "23048",
      //   "FechaAsignacion": "2020-11-06T20:39:36"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0000044",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0000044",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0000046",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0000046",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001021",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-26T22:40:15.287000"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001021",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-26T22:40:15.287000"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001023",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-26T22:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001023",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-26T22:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001029",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-26T22:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001029",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-26T22:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001055",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-26T22:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001055",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-26T22:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001099",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0001099",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002000",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002000",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002001",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002001",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002002",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002002",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002003",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002003",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002004",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002004",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002011",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002011",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002012",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002012",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002020",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0002020",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-27T23:40:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0005000",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-28T10:00:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0005000",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-28T10:00:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0006000",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78753",
      //   "FechaAsignacion": "2020-10-28T08:00:00"
      // },
      // {
      //   "Tienda": "17",
      //   "Remision": "0006000",
      //   "Cliente": "GustavoQF Almeida",
      //   "Sku": "78754",
      //   "FechaAsignacion": "2020-10-28T08:00:00"
      // }
    ];

    let response = new HttpResponse<any>({ body: data, status: 200 });

    return new Observable<HttpResponse<any>>(obs => {
      obs.next(response);
      obs.complete();
    });
  }

  changePassword(username, lastPassword, newPassword) {
    let body = {
      "Username": username,
      "Old_Password": lastPassword,
      "New_Password": newPassword
    };
    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRupResetPassword/", body, { headers: this.headers, observe: 'response' })
    return response;
  }

  changeTienda(orden_completa, order_number, codigoSKU, tienda_destino) {
    let body = {
      "orden_completa": orden_completa,
      "order_number": order_number,
      "listaSkus": [
        {
          "codigoSku": codigoSKU,
          "tienda_destino": tienda_destino
        }
      ]
    }

    console.log(body);
    var url = this.urlService;
    var response = this.http.post(url + "apiXDPRsvReasignacionTienda/", body, { headers: this.headers, observe: 'response' })
    return response;
  }
}
