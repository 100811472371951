import { Component } from '@angular/core';
import { Platform,NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SecurityService } from './security/security.service'
import { Router } from '@angular/router';
import { ConfigXdService } from './config-xd.service'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  env = "";

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private security : SecurityService,
    public navCtrl: NavController,
    private router: Router,
    public configXD : ConfigXdService
  ) {
    let en = this.configXD.getEnvironment();
    console.log("Environment",en);
    if(en=="QA"){
      this.env = en;
    }    
    this.initializeApp();
  }
  

  initializeApp() {
    this.platform.ready().then(() => {
      this.security.validateLogin(); //Valida si el usuario esta logueado
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
