import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigModel } from './app-config-model';

const APP_CONFIG_KEY_NAME: string = 'appConfigKeyName';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

    static config: AppConfigModel;

  constructor(private http: HttpClient) { }

  load() {
    this.http.get('./assets/app-config.json').subscribe((res:AppConfigModel) => {

      AppConfigService.config = new AppConfigModel(res);

    }, err => {
        console.log('Ocorreu um erro ao tentar recuperar o arquivo de configuração', err);
    });
  }
}
