import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  
  operador = {
    user_id : "",
    tienda : "",
    nombreTienda : "",
    permiteXdock : "",
    admin : false
  };

  constructor(
    private storage: Storage,
    private router: Router
    ) {
  }
  setLoginUser(data){
    let formatTiendas = [];
    for (let tienda of data.Tiendas){
      let intNumeroTienda = parseInt(tienda.numeroTienda);
      formatTiendas.push(
        {
         "numeroTienda":intNumeroTienda.toString(),
         "nombreTienda":tienda.nombreTienda,
         "permiteXdock" : tienda.permiteXdock
        }
        );
    }
    data.Tiendas = formatTiendas;
    this.storage.set('livXDusrlogged', data);
  }

  deleteLoggedUser(){
    this.storage.remove('livXDusrlogged');
  }

  cerrarSesion(){
    console.log("Cerrando Sesión");
    this.deleteLoggedUser();
    this.router.navigate(['/login']);
  }

  validateLogin(){
    this.storage.get('livXDusrlogged').then((data) => { 
      console.log(data);
      if(data){
        this.operador.user_id = data.UserID;
        let tienda = data.Tiendas[0];
        this.operador.tienda = tienda.numeroTienda; // Para prueba se selecciona la primera tienda del usuario
        this.operador.nombreTienda = tienda.nombreTienda;
        this.operador.permiteXdock = tienda.permiteXdock;
        if (data.Roles){
          let roles = data.Roles.replace(/'/g,'"');
          let rolAdmin = JSON.parse(roles).Admin;
          if(rolAdmin){
            this.operador.admin = true;
          }
        }
        
        
      }
      else{
        console.log("Usuario inexistente",this.router.url);
        if(this.router.url != "/login"){
          this.cerrarSesion();
        }
      }
    });
  }


  /*
  Vendedor de Piso
  */

  setUserVendedor(data){
    
    this.storage.set('livXDusrvendedor', data);
    
  }

  getUserVendedor(){
    return this.storage.get('livXDusrvendedor');
  }

  

  deleteUserVendedor(){
    this.storage.remove('livXDusrvendedor');
    console.log("Cerrando Sesión Vendedor");
  }

  deleteTiendas(){
    this.storage.remove('livXDTiendas');
    console.log("Removiendo Tiendas");
  }

  cerrarSesionVendedor(){
    this.deleteUserVendedor();
    this.deleteTiendas();
    if(this.router.url === "/vendedor"){
      this.router.navigate(['/vendedor']);
    }
    
  }

  setTiendas(data){
    this.storage.set('livXDTiendas', data);
  }

  getTiendas(){
    return this.storage.get('livXDTiendas');
  }

  

}
