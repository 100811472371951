import { NgModule,CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ComponentsComponent } from './components/components.component'
import { ScannerComponent } from './scanner-component/scanner-component.component'
import { SecurityService } from './security/security.service'
import { PopoverLpsComponent } from './embalaje/popover-lps/popover-lps.component'
import { HttpClientModule } from '@angular/common/http';
import { ServicesxdService } from '../app/api/servicesxd.service';
import { AppConfigService } from '../app/api/app-config.service';
import { IonicStorageModule } from '@ionic/storage';
import { HttpModule } from '@angular/http';
import { NgxPowerBiModule } from 'ngx-powerbi';

@NgModule({
  declarations: [AppComponent,ScannerComponent,PopoverLpsComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    IonicStorageModule.forRoot({
      name: 'xd_',
      driverOrder: ['localstorage']
    }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgxPowerBiModule,
    HttpModule,

  ],  
  schemas : [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    StatusBar,
    SplashScreen,
    ComponentsComponent,
    ScannerComponent,
    PopoverLpsComponent,
    SecurityService,
    ServicesxdService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (service: AppConfigService) => { return () => service.load() },
      multi: true,
      deps: [AppConfigService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
