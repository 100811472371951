import {IonInput, PopoverController} from '@ionic/angular';
import { Component,ViewChild, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import Quagga from '@ericblade/quagga2';

declare global {
  interface Window  {
    Android:any;
  }
 
}

@Component({
  selector: 'app-scanner-component',
  templateUrl: './scanner-component.component.html',
  styleUrls: ['./scanner-component.component.scss'],
  
})

export class ScannerComponent {
  @ViewChild('search') searchElement : IonInput;
  
  storedCode : String = '';
  codigo : String;
  
  constructor(
    public popoverCtrl: PopoverController,
    public router: Router
  ) { 
    Quagga.onDetected((data) => this.callbackScanner(data.codeResult.code));
    
    
    let Android = window.Android;
  }

  
  ionViewDidEnter() {
    setTimeout(() => {//Pongo un retardo para asegurar que la variable this.codigo este llena al ejecutar callback
      this.searchElement.setFocus();
    }, 100);

    this.openScanner();
    
    console.log("ViewDidEnter");
  }
  
  ngOnDestroy () {
    // if(typeof window.Android !== 'undefined' && window.Android !== null){
    //   window.Android.requestScannerOff();
    // }
  }
  keyEnterSubmit(ev){   
    
    if (ev.keyCode === 13) {
      
      setTimeout(() => {//Pongo un retardo para asegurar que la variable this.codigo este llena al ejecutar callback
        this.callbackScanner(this.codigo);
      }, 100);
      
      
    } 
    
  }
  callbackScanner(data : any){
    // Quagga.stop();
    if(data!=null){
      data = data.replace(/,/g, "replacecommas"); //Reemplaza las comas medio por el texto "replacethiscommas" y cuando ya se haya hecho el replace de caracteres especiales regresarlo a la coma
      data = data.replace("-", "replacethis"); //Reemplaza el guion medio por el texto "replacethis" y cuando ya se haya hecho el replace de caracteres especiales regresarlo a guion medio
      data = data.replace("\'", "replacethis"); //Reemplaza la comilla simple por el texto "replacethis" y cuando ya se haya hecho el replace de caracteres especiales regresarlo a guion medio
      //data = data.replace(/[^a-zA-Z0-9]/g, ""); //Remover caracteres especiales
      //data = data.replace(/\s/g, ""); //Remover espacios
      data = data.replace("replacecommas", ","); //Regresa el "replacethiscommas" al guion medio
      data = data.replace("replacethis", "-"); //Regresa el "replacethis" al guion medio
      data = data.toUpperCase()
      console.log(data);
      this.popoverCtrl.dismiss(data);
      this.codigo = '';
      // if(typeof window.Android !== 'undefined' && window.Android !== null){
        
        
      //   //window.Android.requestScannerOff(); //funcion del webview para apagar el en el escanner

      //   if(typeof window.Android !== 'undefined' && window.Android !== null){
      //     console.log('kiosk://monitorxd/scanOff');//Deeplink que enciende el escaner
      //     var a = document.createElement('a');
      //     a.setAttribute('href','kiosk://monitorxd/scanOff'); //<-- this is the URL
      //     document.body.appendChild(a);
      //   }
      // }
      
    } 
  }

  codigoManual(){
    this.callbackScanner(this.codigo);
  }

  openScanner(){
    // if(typeof window.Android !== 'undefined' && window.Android !== null){
    //     console.log('kiosk://monitorxd/scanOn');//Deeplink que enciende el escaner
    //     var a = document.createElement('a');
    //     a.setAttribute('href','kiosk://monitorxd/scanOn'); //<-- this is the URL
    //     document.body.appendChild(a);
    // }

    Quagga.init({
      inputStream : {
        name : "Live",
        type : "LiveStream",
      },
      decoder : {
        readers : ["code_128_reader"]
      },
    }, function(err) {
        if (err) {
            return err;
        }
        console.log("Initialization finished. Ready to start");
        Quagga.start();
        
    });

    }
}